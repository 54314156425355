const initialState = {
    breaking_news: "",
    product_categories: [],
    sliders: [],
    banners: [],
    services: [],
    bannerHome: [],
    sizeSection: [],
    work_samples: [],
    products: [],
    latest_articles: [],
    property: [],
    faqs: [],
    story: []
}

const UIReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UI':
            return {
                ...state,
                breaking_news: action.data ? action.data.breaking_news : "",
                product_categories: action.data ? action.data.product_categories : [],
                property: action.data ? action.data.property : [],
                sliders: action.data ? action.data.sliders : [],
                banners: action.data ? action.data.banners : [],
                services: action.data ? action.data.services : [],
                bannerHome: action.data ? action.data.bannerHome : [],
                sizeSection: action.data ? action.data.sizeSection : [],
                work_samples: action.data ? action.data.work_samples : [],
                products: action.data ? action.data.products : [],
                FAQs: action.data ? action.data.faqs : [],
                questionsPage: action.data ? action.data.faqs : [],
                articles: action.data ? action.data.latest_articles : [],
                story: action.data ? action.data.story : []
            }
        default:
            return state
    }
}

export default UIReducer
